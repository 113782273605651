import { defineStore } from "pinia";
import {
  CountriesShippedByFedex,
  CountryCode,
  CurrenciesToCountryCodes,
  UAECountries,
  USStates,
  countryCodeToPhrase,
  type USState,
  productTypeMapper,
} from "@/utils/constants";
import { useMainStore } from "./main";
import { LocalStorageKeys, Store } from "@/utils/constants";
import { entries_unsafe, addQueryParamToUrl } from "@/utils/functions";
import {
  ProductCategory,
  type AvailableProduct,
  type ShippingZone,
  type OrderSubmit,
  type Profile,
  type ValidateAddressPayload,
  Currency,
} from "@/types/apiTypes";
import type { AddressData } from "@/types/paymentTypes";
import type { CustomerInformationType } from "@/types/paymentTypes";
import { useDiscountStore } from "./discount";
import { OtherRouteKey } from "@/types/questions";
import axios from "axios";
import { API } from "@/utils/api/api_paths";
import camelcaseKeys from "camelcase-keys";
import { getAnswersForOrderSubmit } from "@/utils/serializeAnswers";
import { useSupplementsStore } from "./supplements";
import { StripeService } from "@/services/Stripe";
import parsePhoneNumber from "libphonenumber-js";
import { useQuestionsStore } from "./questions";
import { CurrencyToSymbol } from "@/utils/constants";
import snakecaseKeys from "snakecase-keys";

export const usePaymentStore = defineStore({
  id: "payment",
  state: () => ({
    availableProducts: [] as AvailableProduct[],
    selectedCountryCode: { value: CountryCode.US as string },
    selectedProductId: 0,
    selectedProductCategory: "" as ProductCategory,
    customerInformation: {
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      phoneNumber: {
        value: "",
        countryCode: "",
        isValid: false,
        e164: "",
      },
    } as CustomerInformationType,
    deliveryAddress: {
      address: "",
      apartmentNr: "",
      city: "",
      postalCode: "",
      areaDistrict: "",
      additionalInfo: "",
      state: "",
      lat: 0,
      long: 0,
      fullAddress: "",
    } as AddressData,
    invoiceAddress: {
      address: "",
      apartmentNr: "",
      city: "",
      postalCode: "",
      areaDistrict: "",
      additionalInfo: "",
      state: "",
    } as AddressData,
    sameAddress: true,
    copyrightSelected: true,
    marketingConsentSelected: false,
    fetchedDefaultProducts: false,
    addressCorrectConsentSelected: false,
    showAddressCorrectConsent: false,
  }),
  getters: {
    currStore() {
      const mainStore = useMainStore();
      return mainStore.store;
    },
    countryCodesAll(): string[] {
      const mainStore = useMainStore();
      return mainStore.countryCodesAll;
    },
    isSelectedCountryDelivereable(): boolean {
      /**
       *  This Type Assertion is just to fool the compiler, in reality the @var this.selectedCountryCode.value variable
       *  can be a string because there are other countries outside from the stores we have
       */
      return this.countryCodesAll.includes(this.selectedCountryCode.value);
    },
    selectedProduct(): AvailableProduct | null {
      if (!this.selectedProductCategory || !this.availableProducts.length) return null;
      return this.availableProducts.find((ap) => ap.category === this.selectedProductCategory)!;
    },
    singlePurchaseAvailableProduct(): AvailableProduct {
      return this.availableProducts.find((ap) => ap.category === ProductCategory.GO_SINGLE_PURCHASE)!;
    },
    subscriptionAvailableProduct(): AvailableProduct {
      return this.availableProducts.find((ap) => ap.category === ProductCategory.GO_SUBSCRIPTION)!;
    },
    singlePurchaseShippingZone(): ShippingZone | null {
      if (!this.singlePurchaseAvailableProduct) return null;
      return this.singlePurchaseAvailableProduct!.shippingZones[0];
    },
    delAddressValid(): boolean {
      const optionalFields: (keyof typeof this.deliveryAddress)[] = [
        "additionalInfo",
        "areaDistrict",
        "lat",
        "long",
        "fullAddress",
        "apartmentNr",
      ];
      if (!this.isSelectedCountryUnitedStates) {
        optionalFields.push("state");
      }
      if (UAECountries.includes(this.selectedCountryCode.value)) {
        optionalFields.push("postalCode");
      }
      const validDelObj = { ...this.deliveryAddress };
      optionalFields.forEach((field) => delete validDelObj[field]);

      const delAddValid = Object.values(validDelObj).every((val) => !!val);
      return delAddValid;
    },
    custInfAndDelValid(): boolean {
      return Object.values(this.customerInformation).every((val) => !!val) && this.delAddressValid;
    },
    spShippingZone(): ShippingZone | null {
      //* this assumes that there will always be just 1 shipping zone per each product

      if (!this.selectedProduct) return null;
      return this.selectedProduct!.shippingZones[0];
    },
    firsthMonthDiscountedPrice(): number {
      if (!this.selectedProduct) return 0;
      const discountStore = useDiscountStore();
      return parseFloat((this.selectedProduct!.price - discountStore.getDiscountVal(this.selectedProduct!)).toFixed(2));
    },
    firstMonthFinalPrice(): number {
      if (!this.spShippingZone) return 0;
      if (this.firsthMonthDiscountedPrice <= 0) return 0;

      return this.firsthMonthDiscountedPrice + this.spShippingZone.deliveryPrice;
    },
    deliveryAddressDetails(): string {
      const firstLine = this.deliveryAddress.areaDistrict;
      const secondLine = this.deliveryAddress.additionalInfo;
      if (firstLine && secondLine) return `${firstLine}, ${secondLine}`;
      if (firstLine) return firstLine;
      if (secondLine) return secondLine;
      return "" as never;
    },
    invoiceAddressDetails(): string {
      const firstLine = this.invoiceAddress.areaDistrict;
      const secondLine = this.invoiceAddress.additionalInfo;
      if (firstLine && secondLine) return `${firstLine}, ${secondLine}`;
      if (firstLine) return firstLine;
      if (secondLine) return secondLine;
      return "" as never;
    },
    orderSubmitPayload(): OrderSubmit {
      const order = {} as OrderSubmit;
      const mainStore = useMainStore();
      const supplementsStore = useSupplementsStore();
      const discountStore = useDiscountStore();
      const questionsStore = useQuestionsStore();

      order.fullSupplementFormula = supplementsStore.getFormulaForSubmitApi;
      order.bioniqGoQuestionnaire = getAnswersForOrderSubmit() as { questionKey: string; answerKeys: string[] }[];

      order.productTypeId = this.selectedProductId;
      order.shippingZoneId = this.spShippingZone!.id;
      order.storeBrand = "bioniq";
      order.funnel = "stripe";
      order.storeCountry = mainStore.store;

      const zipMe = (selectedCountryCode: string, code: string) => {
        let newCode = code;
        if (UAECountries.includes(selectedCountryCode)) {
          if (!code) newCode = "0";
        }
        return newCode;
      };

      const profile: Profile = {
        ...questionsStore.profileQuestions,
        firstName: this.customerInformation.firstName,
        lastName: this.customerInformation.lastName,
        phone: this.customerInformation.phoneNumber.e164,
        email: this.customerInformation.email,
        countryCode: this.selectedCountryCode.value,
        city: this.invoiceAddress.city,
        zip: zipMe(this.selectedCountryCode.value, this.invoiceAddress.postalCode),
        streetAddress: this.invoiceAddress.address,
        deliveryAddress: {
          streetAddress: this.deliveryAddress.address,
          zip: zipMe(this.currStore, this.deliveryAddress.postalCode),
          city: this.deliveryAddress.city,
          countryCode: this.selectedCountryCode.value,
        },
      };
      if (this.deliveryAddressDetails) profile.deliveryAddress.addressDetails = this.deliveryAddressDetails;
      if (this.deliveryAddress.apartmentNr) {
        profile.deliveryAddress.streetAddress = `${this.deliveryAddress.apartmentNr}, ${this.deliveryAddress.address}`;
      }
      if (this.deliveryAddress.lat && this.deliveryAddress.long) {
        profile.deliveryAddress.latitude = this.deliveryAddress.lat;
        profile.deliveryAddress.longitude = this.deliveryAddress.long;
      }
      if (this.invoiceAddress.apartmentNr) {
        profile.streetAddress = `${this.invoiceAddress.apartmentNr}, ${this.invoiceAddress.address}`;
      }
      if (this.invoiceAddressDetails) profile.addressDetails = this.invoiceAddressDetails;
      order.profile = profile;

      if (discountStore.hasActiveVoucher) order.discountCode = discountStore.code;
      if (discountStore.hasActiveReferral) order.bioniqReferralCode = discountStore.referral.code;

      // ----- United States State property -----
      if (this.deliveryAddress.state && this.invoiceAddress.state) {
        profile.deliveryAddress.stateCode = this.deliveryAddress.state;
        profile.deliveryAddress.state = USStates.find((state) => state.code === this.deliveryAddress.state)!.name;
        profile.stateCode = this.invoiceAddress.state;
        profile.state = USStates.find((state) => state.code === this.invoiceAddress.state)!.name;
      }
      // ----- United States State property -----

      return order;
    },
    customerFullName(): string {
      return `${this.customerInformation.firstName} ${this.customerInformation.lastName}`;
    },
    isSelectedCountryUnitedStates(): boolean {
      return this.selectedCountryCode.value === CountryCode.US;
    },
    isSelectedCountryCodeShippableByFedex(): boolean {
      return CountriesShippedByFedex.some((cc) => cc === this.selectedCountryCode.value);
    },
    currencyToPay(): Currency {
      const cc = this.selectedCountryCode.value;
      let currencyResult = Currency.USD;

      entries_unsafe(CurrenciesToCountryCodes).forEach(([currency, countryCodes]) => {
        if (countryCodes.includes(cc as CountryCode)) currencyResult = currency;
      });

      if (!Object.values(CountryCode).includes(cc as CountryCode)) currencyResult = Currency.USD;
      return currencyResult;
    },
  },
  actions: {
    setAvailableProducts(aps: AvailableProduct[]) {
      this.availableProducts = aps;
    },
    setSelectedCountryCode(code: string) {
      this.selectedCountryCode.value = code;
    },
    isShippingPossible(products: AvailableProduct[]): boolean {
      return products.every((pr) => !!pr.shippingZones.length);
    },
    async fetchAvailableProducts() {
      this.setFetchedDefaultProducts(false);
      try {
        const mainStore = useMainStore();
        let url = API.AVAILABLE_PRODUCTS.replace("{STORE}", mainStore.store)
          .replace("{CC}", this.selectedCountryCode.value!)
          .replace("{CURRENCY}", this.currencyToPay);
        if (mainStore.checkoutVersion) url += `&ab_test_key=${mainStore.checkoutVersion}`;

        const res: any = await axios.get(url);
        let products: AvailableProduct[] = camelcaseKeys(res.data.product_types, { deep: true });
        if (!this.isShippingPossible(products)) {
          const [defErr, defRes] = await this.fetchAvailableProductsDefaultAsUSA();
          if (defErr) throw new Error(defErr);

          products = defRes;
        }
        this.setAvailableProducts(products);
        return [null, true];
      } catch (err) {
        console.error("Error on the GET available_products request, ", err);
        return [true, null];
      }
    },
    async fetchAvailableProductsDefaultAsUSA() {
      this.setFetchedDefaultProducts(true);
      const mainStore = useMainStore();

      let url = API.AVAILABLE_PRODUCTS.replace("{STORE}", Store.USA)
        .replace("{CC}", CountryCode.US)
        .replace("{CURRENCY}", Currency.USD);
      if (mainStore.checkoutVersion) url += `&ab_test_key=${mainStore.checkoutVersion}`;

      try {
        const res: any = await axios.get(url);
        return [null, camelcaseKeys(res.data.product_types, { deep: true })];
      } catch (err) {
        console.error("Error on the GET available_products request with US country code, ", err);
        return [true, null];
      }
    },
    setFetchedDefaultProducts(val: boolean) {
      this.fetchedDefaultProducts = val;
    },
    setSelectedProductId(id: number) {
      this.selectedProductId = id;
    },
    setSelectedProductCategory(category: ProductCategory) {
      this.selectedProductCategory = category;
    },
    updateCustomerInformationData(data: CustomerInformationType) {
      this.customerInformation.firstName = data.firstName;
      this.customerInformation.lastName = data.lastName;
      this.customerInformation.email = data.email;
      this.customerInformation.confirmEmail = data.confirmEmail;
      const { value, countryCode, isValid, e164 } = data.phoneNumber;
      this.customerInformation.phoneNumber = {
        value,
        countryCode,
        isValid,
        e164,
      };
    },
    updatePhoneNumber(phoneNumber: string) {
      if (!phoneNumber) return this.resetPhoneNumber();

      const parsedPhNumber: any = parsePhoneNumber(phoneNumber);
      if (!parsedPhNumber) return;

      this.customerInformation.phoneNumber.value = parsedPhNumber.number;
      this.customerInformation.phoneNumber.e164 = parsedPhNumber.number;
      this.customerInformation.phoneNumber.countryCode = parsedPhNumber.country;
      this.customerInformation.phoneNumber.isValid = true;
    },
    updateAddressDetails(addressType: "deliveryAddress" | "invoiceAddress", data: AddressData) {
      this[addressType].city = data.city;
      this[addressType].address = data.address;
      this[addressType].apartmentNr = data.apartmentNr;
      this[addressType].postalCode = data.postalCode;
      this[addressType].areaDistrict = data.areaDistrict;
      this[addressType].additionalInfo = data.additionalInfo;
      this[addressType].state = data.state;
      this[addressType].fullAddress = data.fullAddress;
      this[addressType].lat = data.lat;
      this[addressType].long = data.long;
    },
    setInvoiceAddressSameAsDelivery() {
      this.invoiceAddress.city = this.deliveryAddress.city;
      this.invoiceAddress.address = this.deliveryAddress.address;
      this.invoiceAddress.apartmentNr = this.deliveryAddress.apartmentNr;
      this.invoiceAddress.postalCode = this.deliveryAddress.postalCode;
      this.invoiceAddress.areaDistrict = this.deliveryAddress.areaDistrict;
      this.invoiceAddress.additionalInfo = this.deliveryAddress.additionalInfo;
      this.invoiceAddress.state = this.deliveryAddress.state;
    },
    resetPhoneNumber() {
      this.customerInformation.phoneNumber.value = "";
      this.customerInformation.phoneNumber.e164 = "";
      this.customerInformation.phoneNumber.countryCode = "";
      this.customerInformation.phoneNumber.isValid = false;
    },
    resetAddress(addressType: "deliveryAddress" | "invoiceAddress") {
      this[addressType].city = "";
      this[addressType].address = "";
      this[addressType].apartmentNr = "";
      this[addressType].postalCode = "";
      this[addressType].areaDistrict = "";
      this[addressType].additionalInfo = "";
      this[addressType].state = "";
      this[addressType].fullAddress = "";
      this[addressType].lat = 0;
      this[addressType].long = 0;
    },
    setSameAddress(val: boolean) {
      this.sameAddress = val;
    },
    onPaymentSuccess({ free, secret }: { free: boolean; secret: string }) {
      const freeQuery = free ? "free" : "";
      this.router.push({
        name: OtherRouteKey.THANK_YOU,
        query: { ...this.router.currentRoute.value.query, secret, free: freeQuery },
      });
    },
    toggleCopyright() {
      this.copyrightSelected = !this.copyrightSelected;
    },
    toggleMarketingConsent() {
      this.marketingConsentSelected = !this.marketingConsentSelected;
    },
    setAddressCorrectConsent(val: boolean) {
      this.addressCorrectConsentSelected = val;
    },
    setShowAddressCorrectConsent(val: boolean) {
      this.showAddressCorrectConsent = val;
    },
    async getClientSecret() {
      return await StripeService.getClientSecret(this.orderSubmitPayload);
    },
    async initializeStripe(key: string, errorCb: (text: string) => void, uiCb: () => void) {
      StripeService.setCallbacks(this.onPaymentSuccess, errorCb, uiCb);
      return await StripeService.initializeStripe(key);
    },
    saveEventPurchaseDataToLocalStorage() {
      if (!this.selectedProduct || !this.spShippingZone) return;

      const discountStore = useDiscountStore();
      const mainStore = useMainStore();

      localStorage.setItem(LocalStorageKeys.selectedProductCategory, this.selectedProductCategory);
      localStorage.setItem(
        LocalStorageKeys.selectedPaymentMethodType,
        StripeService.listened.selectedPaymentMethodType
      );

      const deliveryObj = {
        country: countryCodeToPhrase[this.selectedCountryCode.value as CountryCode],
        city: this.deliveryAddress.city,
        address: this.deliveryAddress.address,
        state: this.deliveryAddress.state,
      };

      const productCurrency = CurrencyToSymbol[this.selectedProduct.currency];
      const discountVal = discountStore.getDiscountVal(this.selectedProduct!);
      const paymentObj = {
        subTotal: `${productCurrency}${this.selectedProduct.price}`,
        discount: `${discountVal ? "-" : ""}${productCurrency}${discountVal}`,
        shipping: `${productCurrency}${this.spShippingZone.deliveryPrice}`,
        total: `${productCurrency}${this.firstMonthFinalPrice}`,
      };

      localStorage.setItem(LocalStorageKeys.ty_payment, JSON.stringify(paymentObj));
      localStorage.setItem(LocalStorageKeys.ty_delivery, JSON.stringify(deliveryObj));
      localStorage.setItem(LocalStorageKeys.ty_phone, this.customerInformation.phoneNumber.e164);
      localStorage.setItem(LocalStorageKeys.ty_email, this.customerInformation.email);
      localStorage.setItem(LocalStorageKeys.ty_fullName, this.customerFullName);
      localStorage.setItem(LocalStorageKeys.locale, mainStore.locale);
    },
    setStripeBillingDetails() {
      const line2 = (() => {
        if (this.invoiceAddress.areaDistrict && this.invoiceAddress.additionalInfo)
          return `${this.invoiceAddress.areaDistrict} + ${this.invoiceAddress.additionalInfo}`;
        return this.invoiceAddress.areaDistrict || this.invoiceAddress.additionalInfo || "";
      })();
      const values = {
        billingDetails: {
          name: this.customerFullName,
          email: this.customerInformation.email,
          phone: this.customerInformation.phoneNumber.e164,
          address: {
            line1: this.invoiceAddress.address,
            line2,
            city: this.invoiceAddress.city,
            country: this.selectedCountryCode.value,
            postal_code: this.invoiceAddress.postalCode,
            state: this.invoiceAddress.state,
          },
        },
      };

      StripeService.setBillingDetails(values);
    },
    async validateAddress(): Promise<[boolean, boolean]> {
      const payload: ValidateAddressPayload = {
        provider: "fedex",
        countryCode: this.selectedCountryCode.value as CountryCode,
        addressLine: this.deliveryAddress.address,
        postalCode: this.deliveryAddress.postalCode,
        state: this.deliveryAddress.state as USState["code"],
        city: this.deliveryAddress.city,
      };

      try {
        const axiosProductionInstance = axios.create({
          headers: {
            "Client-App":
              import.meta.env.VITE_NODE_ENV === "production"
                ? import.meta.env.VITE_CLIENT_APP
                : import.meta.env.VITE_CLIENT_APP_PROD,
          },
        });
        const res: any = await axiosProductionInstance.post(API.VALIDATE_ADDRESS, snakecaseKeys(payload));
        if (res.valid) return [false, true];

        if (!res.data.valid || res.data.errors.length) {
          console.warn(`The Address couldn't be validated by Fedex`);
          return [true, false];
        }
      } catch (err) {
        console.error(`Error on the /shipments/validate_address API, ${err}`);
        return [true, false];
      }

      return [false, false] as never;
    },
    constructCheckoutShopifyLink(): string {
      const questionsStore = useQuestionsStore();

      const envLink = import.meta.env.VITE_CHECKOUT_SHOPIFY_LINK;
      if (!envLink) return "";

      const mainStore = useMainStore();
      const supplementsStore = useSupplementsStore();
      let url = envLink
        .replace("{product_type}", productTypeMapper[this.selectedProductCategory])
        .replace("{lang}", mainStore.locale)
        .replace("{formula_draft_uuid}", supplementsStore.formulaDraftUuid);

      url = addQueryParamToUrl(url, { key: "country_code", value: this.selectedCountryCode.value });
      url = addQueryParamToUrl(url, {
        key: "formula_email",
        value: questionsStore.lifestyleStore.email,
      });

      Object.entries(this.router.currentRoute.value.query).forEach(([key, value]) => {
        url += `&${key}=${value}`;
      });
      return url;
    },
  },
});
