import { defineStore } from "pinia";
import { useQuestionsStore } from "@/stores/questions";
import {
  type Question,
  HealthQuestionKey,
  type QuestionMultiple,
  QuestionType,
  type QuestionAnswer,
  SubSectionKey,
  SectionKey,
  type AnswerNumberList,
  type QuestionRadio,
  type AnswerNumber,
  type QuestionYesNo,
  type QuestionText,
  type QuestionCheckbox,
  type QuestionKey,
  type AnswerText,
  WhyType,
} from "@/types/questions";
import {
  concernToSubsectionKey,
  HealthConcernsOptions,
  YesNoOptions,
  CurrentFitnessGoalsOptions,
  HealthConcernsValues,
} from "@/utils/constants";
import { createQuestionRoute, createSectionRoute } from "@/router/guards";
import { findValueByIdInOptionsList } from "@/utils/functions";
import type { QuestionOption } from "@/types/uiTypes";

export type CurrentFitnessGoalValue = "build-muscle" | "lose-weight" | "improve-stamina" | "general-well" | null;

const introHealthQuestions: Question[] = [
  {
    key: HealthQuestionKey.GENDER,
    step: 0,
    active: true,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.whyWeAsk,
    subSection: SubSectionKey.HEALTH_GOALS,
    allValues: [
      { id: 2, value: "female", phraseKey: "FEMALE" },
      { id: 1, value: "male", phraseKey: "MALE" },
      { id: 3, value: "none", phraseKey: "PREFER_NOT_SAY" },
    ],
    activeGroup: [],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.CONCERNS,
    active: true,
    type: QuestionType.MULTIPLE,
    hasWhyModal: false,
    hasHint: true,
    subSection: SubSectionKey.HEALTH_GOALS,
    allValues: HealthConcernsOptions,
    max: 3,
  } as QuestionMultiple,
];

const optionalSectionQuestions = [
  {
    key: HealthQuestionKey.BRAIN_FOG,
    active: false,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.MEMORY_FOCUS,
    allValues: [
      { id: 18, value: "never", phraseKey: "NEVER" },
      { id: 19, value: "rarely", phraseKey: "RARELY" },
      { id: 20, value: "frequently", phraseKey: "FREQUENTLY" },
      { id: 21, value: "all-the-time", phraseKey: "ALL_THE_TIME" },
    ],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.COGNITIVE,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: false,
    subSection: SubSectionKey.MEMORY_FOCUS,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.BLOOD_PRESSURE,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.HEART_BLOOD,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.EDEMA,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.HEART_BLOOD,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.ANXIETY,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.SLEEP_ANXIETY,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.MOOD,
    active: false,
    type: QuestionType.RADIO,
    hasWhyModal: false,
    subSection: SubSectionKey.SLEEP_ANXIETY,
    allValues: [
      { id: 22, value: "mood-happy", phraseKey: "MOOD_HAPPY" },
      { id: 23, value: "mood-swings", phraseKey: "MOOD_SWINGS" },
      { id: 24, value: "mood-sad", phraseKey: "MOOD_SAD" },
    ],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.DESPRESSION,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.SLEEP_ANXIETY,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.ASLEEP,
    active: false,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.SLEEP_ANXIETY,
    allValues: [
      { id: 25, value: "rarely", phraseKey: "RARELY" },
      { id: 26, value: "1-2-times", phraseKey: "1_2_TIMES" },
      { id: 27, value: "2-4-times", phraseKey: "2_4_TIMES" },
      { id: 28, value: "more-4-times", phraseKey: "MORE_4_TIMES" },
    ],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.PANIC,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.SLEEP_ANXIETY,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.FITNESS_GOAL,
    active: false,
    type: QuestionType.RADIO,
    hasWhyModal: false,
    hasHint: true,
    subSection: SubSectionKey.MUSCLE_STRENGTH,
    allValues: CurrentFitnessGoalsOptions,
  } as QuestionRadio,
  {
    key: HealthQuestionKey.PHYSICAL_ACTIVITY,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.MUSCLE_STRENGTH,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.MENATAL_WORN_OUT,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: false,
    subSection: SubSectionKey.MUSCLE_STRENGTH,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.JOINT_PAIN,
    active: false,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.BONES_JOINTS,
    allValues: [
      { id: 32, value: "osteoarthritis", phraseKey: "OSTEOARTHRITIS" },
      { id: 33, value: "rheumatoid-arthritis", phraseKey: "RHEUMATOID_ARTHRITIS" },
      { id: 34, value: "sport-injuries", phraseKey: "SPORT_INJURIES" },
      { id: 35, value: "no-joint-pain", phraseKey: "NO_JOINT_PAIN" },
    ],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.BROKEN,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.BONES_JOINTS,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.SKIN_TYPE,
    active: false,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.SKIN_HAIR_NAILS,
    allValues: [
      { id: 36, value: "dry-skin", phraseKey: "DRY_SKIN" },
      { id: 37, value: "oily-skin", phraseKey: "OILY_SKIN" },
      { id: 38, value: "unueven-skin", phraseKey: "UNEVEN_SKIN" },
      { id: 39, value: "tired-skin", phraseKey: "TIRED_SKIN" },
      { id: 40, value: "normal-skin", phraseKey: "NORMAL_SKIN" },
    ],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.SKIN_ISSUES,
    active: false,
    type: QuestionType.CHECKBOX,
    hasWhyModal: false,
    subSection: SubSectionKey.SKIN_HAIR_NAILS,
    allValues: [
      { id: 41, value: "skin-breakouts", phraseKey: "SKIN_BREAKOUTS" },
      { id: 42, value: "skin-hyperpigmentation", phraseKey: "SKIN_HYPERPIGMENTATION" },
      { id: 43, value: "skin-wrinkles", phraseKey: "SKIN_WRINKLES" },
      { id: 44, value: "skin-elasticity", phraseKey: "SKIN_ELASTICITY" },
      { id: 45, value: "skin-aging", phraseKey: "SKIN_AGING" },
      { id: 46, value: "none-above", phraseKey: "NONE_ABOVE", negative: true },
    ],
  } as QuestionCheckbox,
  {
    key: HealthQuestionKey.SKIN_BREAKOUTS,
    active: false,
    type: QuestionType.RADIO,
    hasWhyModal: false,
    subSection: SubSectionKey.SKIN_HAIR_NAILS,
    allValues: [
      { id: 47, value: "never", phraseKey: "NEVER" },
      { id: 48, value: "rarely", phraseKey: "RARELY" },
      { id: 49, value: "frequently", phraseKey: "FREQUENTLY" },
      { id: 50, value: "all-the-time", phraseKey: "ALL_THE_TIME" },
    ],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.SKIN_FLAKY,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: false,
    subSection: SubSectionKey.SKIN_HAIR_NAILS,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.HAIR,
    active: false,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.SKIN_HAIR_NAILS,
    allValues: [
      { id: 51, value: "hair-loss", phraseKey: "HAIR_LOSS" },
      { id: 52, value: "hair-dry", phraseKey: "HAIR_DRY" },
      { id: 53, value: "hair-damaged", phraseKey: "HAIR_DAMAGED" },
      { id: 54, value: "hair-slow", phraseKey: "HAIR_SLOW" },
      { id: 55, value: "none-above", phraseKey: "NONE_ABOVE" },
    ],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.FINGERNAILS,
    active: false,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.SKIN_HAIR_NAILS,
    allValues: [
      { id: 56, value: "nails-strength", phraseKey: "NAILS_STRENGTH" },
      { id: 57, value: "nails-growth", phraseKey: "NAILS_GROWTH" },
      { id: 58, value: "nails-condition", phraseKey: "NAILS_CONDITION" },
      { id: 59, value: "none-above", phraseKey: "NONE_ABOVE" },
    ],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.CITY,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: false,
    subSection: SubSectionKey.SKIN_HAIR_NAILS,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.PROCEDURE,
    active: false,
    type: QuestionType.TEXT,
    hasWhyModal: false,
    subSection: SubSectionKey.OPERATIVE_PERIOD,
  } as QuestionText,
  {
    key: HealthQuestionKey.OPERATIVE_CONCERN,
    active: false,
    type: QuestionType.RADIO,
    hasWhyModal: false,
    subSection: SubSectionKey.OPERATIVE_PERIOD,
    allValues: [
      { id: 60, value: "preparation", phraseKey: "PREPARATION" },
      { id: 61, value: "post-rehab", phraseKey: "POST_REHAB" },
      { id: 62, value: "rehab", phraseKey: "REHAB" },
    ],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.SEX_DRIVE,
    active: false,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.LIBIDO,
    allValues: [
      { id: 62, value: "high", phraseKey: "HIGH" },
      { id: 63, value: "average", phraseKey: "AVERAGE" },
      { id: 64, value: "low", phraseKey: "LOW" },
    ],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.IMPROVE_SEX,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: false,
    subSection: SubSectionKey.LIBIDO,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.LIBIDO_SIMPTOMS,
    active: false,
    type: QuestionType.CHECKBOX,
    hasWhyModal: false,
    subSection: SubSectionKey.LIBIDO,
    allValues: [
      { id: 65, value: "arousal", phraseKey: "LIBIDO_AROUSAL" },
      { id: 66, value: "dryness", phraseKey: "LIBIDO_DRYNESS" },
      { id: 67, value: "painful", phraseKey: "LIBIDO_PAINFUL" },
      { id: 68, value: "trouble", phraseKey: "LIBIDO_TROUBLE" },
      { id: 69, value: "dont", phraseKey: "LIBIDO_DONT", negative: true },
    ],
    activeGroup: [HealthQuestionKey.LIBIDO_SIMPTOMS],
  } as QuestionCheckbox,
  {
    key: HealthQuestionKey.ERECTILE_SIMPTOMS,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: false,
    subSection: SubSectionKey.LIBIDO,
    activeGroup: [HealthQuestionKey.ERECTILE_SIMPTOMS],
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.FERTILITY,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.LIBIDO,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.ALCOHOLIC,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.LIVER_FUNCTION,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.ANEMIA,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: false,
    subSection: SubSectionKey.IRON,
  } as QuestionYesNo,
];

const lastHealthQuestions: Question[] = [
  {
    key: HealthQuestionKey.COLDS,
    active: true,
    type: QuestionType.YES_NO,
    hasWhyModal: false,
    subSection: SubSectionKey.HEALTH_CONCERNS,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.CONCENTRATING,
    active: true,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.HEALTH_CONCERNS,
    allValues: [
      { id: 70, value: "never", phraseKey: "NEVER" },
      { id: 71, value: "rarely", phraseKey: "RARELY" },
      { id: 72, value: "frequently", phraseKey: "FREQUENTLY" },
      { id: 73, value: "all-the-time", phraseKey: "ALL_THE_TIME" },
    ],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.ENERGY,
    active: true,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.HEALTH_CONCERNS,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.SLEEP_QUALITY,
    active: true,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.HEALTH_CONCERNS,
    allValues: [
      { id: 74, value: "great", phraseKey: "GREAT" },
      { id: 75, value: "good", phraseKey: "GOOD" },
      { id: 76, value: "improvement", phraseKey: "IMPROVEMENT" },
      { id: 77, value: "poor", phraseKey: "POOR" },
    ],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.SEEING,
    active: true,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.HEALTH_CONCERNS,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.DRY_EYES,
    active: true,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.HEALTH_CONCERNS,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.STRESS,
    active: true,
    type: QuestionType.RADIO,
    hasWhyModal: false,
    subSection: SubSectionKey.HEALTH_CONCERNS,
    allValues: [
      { id: 78, value: "rarely", phraseKey: "RARELY_STRESSED" },
      { id: 79, value: "sometimes", phraseKey: "SOMETIMES_STRESSED" },
      { id: 80, value: "constantly", phraseKey: "CONSTANTLY_STRESSED" },
    ],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.ATHLETE,
    active: true,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.whyWeAsk,
    subSection: SubSectionKey.HEALTH_CONCERNS,
  } as QuestionYesNo,
  {
    key: HealthQuestionKey.SWEAT,
    active: false,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.HEALTH_CONCERNS,
    allValues: [
      { id: 81, value: "never", phraseKey: "NEVER" },
      { id: 82, value: "sometimes", phraseKey: "SOMETIMES" },
      { id: 83, value: "all-the-time", phraseKey: "ALL_THE_TIME" },
    ],
    activeGroup: [
      HealthQuestionKey.SWEAT,
      HealthQuestionKey.WORKOUT,
      HealthQuestionKey.CRAMPS,
      HealthQuestionKey.MUSCLE_INJURIES,
      HealthQuestionKey.INJURIES,
    ],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.WORKOUT,
    active: false,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.HEALTH_CONCERNS,
    allValues: [
      { id: 84, value: "regular", phraseKey: "REGULAR_WORKOUT" },
      { id: 85, value: "competion", phraseKey: "COMPETITION" },
      { id: 86, value: "not-working", phraseKey: "NOT_WORKING" },
    ],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.CRAMPS,
    active: false,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.HEALTH_CONCERNS,
    allValues: [
      { id: 87, value: "never", phraseKey: "NEVER" },
      { id: 88, value: "sometimes", phraseKey: "SOMETIMES" },
      { id: 89, value: "frequently", phraseKey: "FREQUENTLY" },
      { id: 90, value: "all-the-time", phraseKey: "ALL_THE_TIME" },
    ],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.MUSCLE_INJURIES,
    active: false,
    type: QuestionType.RADIO,
    hasWhyModal: false,
    subSection: SubSectionKey.HEALTH_CONCERNS,
    allValues: [
      { id: 91, value: "never", phraseKey: "NEVER" },
      { id: 92, value: "sometimes", phraseKey: "SOMETIMES" },
      { id: 93, value: "frequently", phraseKey: "FREQUENTLY" },
      { id: 94, value: "all-the-time", phraseKey: "ALL_THE_TIME" },
    ],
  } as QuestionRadio,
  {
    key: HealthQuestionKey.INJURIES,
    active: false,
    type: QuestionType.CHECKBOX,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.HEALTH_CONCERNS,
    allValues: [
      { id: 70, value: "bone-injury", phraseKey: "BONE_INJURY" },
      { id: 71, value: "muscle-injury", phraseKey: "MUSCLE_INJURY" },
      { id: 72, value: "joint-injury", phraseKey: "JOINT_INJURY" },
      { id: 73, value: "tendon-injury", phraseKey: "TENDON_INJURY" },
      { id: 74, value: "no-njury", phraseKey: "NO_INJURY", negative: true },
    ],
  } as QuestionCheckbox,
];

export const useHealthStore = defineStore({
  id: "health",
  state: () => ({
    questions: [...introHealthQuestions, ...optionalSectionQuestions, ...lastHealthQuestions],
    answers: {
      "h-gender": { value: null } as AnswerNumber,
      "h-concerns": { value: [] } as AnswerNumberList,
      "h-brain-fog": { value: null } as AnswerNumber,
      "h-cognitive": { value: null } as AnswerNumber,
      "h-blood-pressure": { value: null } as AnswerNumber,
      "h-edema": { value: null } as AnswerNumber,
      "h-anxiety": { value: null } as AnswerNumber,
      "h-mood": { value: null } as AnswerNumber,
      "h-depression": { value: null } as AnswerNumber,
      "h-asleep": { value: null } as AnswerNumber,
      "h-panic": { value: null } as AnswerNumber,
      "h-fitness-goal": { value: null } as AnswerNumber,
      "h-physical-activity": { value: null } as AnswerNumber,
      "h-mental-worn-out": { value: null } as AnswerNumber,
      "h-joint-pain": { value: null } as AnswerNumber,
      "h-broken": { value: null } as AnswerNumber,
      "h-skin-type": { value: null } as AnswerNumber,
      "h-skin-issues": { value: [] } as AnswerNumberList,
      "h-skin-breakouts": { value: null } as AnswerNumber,
      "h-skin-flaky": { value: null } as AnswerNumber,
      "h-hair": { value: null } as AnswerNumber,
      "h-fingernails": { value: null } as AnswerNumber,
      "h-city": { value: null } as AnswerNumber,
      "h-procedure": { value: "" } as AnswerText,
      "h-operative-concern": { value: null } as AnswerNumber,
      "h-immune-support": { value: null } as AnswerNumber,
      "h-sex-drive": { value: null } as AnswerNumber,
      "h-improve-sex": { value: null } as AnswerNumber,
      "h-libido-simptoms": { value: [] } as AnswerNumberList,
      "h-erectile-simptoms": { value: null } as AnswerNumber,
      "h-fertility": { value: null } as AnswerNumber,
      "h-alcoholic": { value: null } as AnswerNumber,
      "h-anemia": { value: null } as AnswerNumber,
      "h-colds": { value: null } as AnswerNumber,
      "h-concentrating": { value: null } as AnswerNumber,
      "h-energy": { value: null } as AnswerNumber,
      "h-sleep-quality": { value: null } as AnswerNumber,
      "h-seeing": { value: null } as AnswerNumber,
      "h-dry-eyes": { value: null } as AnswerNumber,
      "h-stress": { value: null } as AnswerNumber,
      "h-athlete": { value: null } as AnswerNumber,
      "h-sweat": { value: null } as AnswerNumber,
      "h-workout": { value: null } as AnswerNumber,
      "h-cramps": { value: null } as AnswerNumber,
      "h-muscle-injuries": { value: null } as AnswerNumber,
      "h-injuries": { value: [] } as AnswerNumberList,
    } as QuestionAnswer,
  }),
  getters: {
    activeHealthQuestions(): Question[] {
      return this.questions.filter((q) => q.active) as Question[];
    },
    questBrainFog(): QuestionRadio {
      return this.questions.find((q) => q.key === HealthQuestionKey.BRAIN_FOG) as QuestionRadio;
    },
    firstQuestionKey(): QuestionKey {
      return this.questions[0].key;
    },
    questGender(): QuestionRadio {
      return this.questions.find((q) => q.key === HealthQuestionKey.GENDER) as QuestionRadio;
    },
    gender(): "female" | "male" | "none" {
      const genderId = (this.answers[HealthQuestionKey.GENDER] as AnswerNumber).value;
      return genderId
        ? (findValueByIdInOptionsList(this.questGender.allValues, genderId) as "female" | "male" | "none")
        : "male";
    },
    commonColdsAnswer(): boolean {
      return (this.answers[HealthQuestionKey.COLDS] as AnswerNumber).value === 1;
    },
    questCurrentFitnessGoal(): QuestionRadio {
      return this.questions.find((q) => q.key === HealthQuestionKey.FITNESS_GOAL) as QuestionRadio;
    },
    currentFitnessGoalsWithAnswer(): CurrentFitnessGoalValue {
      const answer = this.answers[HealthQuestionKey.FITNESS_GOAL] as AnswerNumber;
      if (!answer.value) return null;
      return CurrentFitnessGoalsOptions.find((goal) => goal.id === answer.value)!.value as CurrentFitnessGoalValue;
    },
    bloodPressureAnswer(): boolean {
      return (this.answers[HealthQuestionKey.BLOOD_PRESSURE] as AnswerNumber).value === 1;
    },
    concernsSelectedOptions(): QuestionOption[] {
      const answers = this.answers[HealthQuestionKey.CONCERNS] as AnswerNumberList;
      return answers.value.map((goalId) => HealthConcernsOptions.find((goal) => goal.id === goalId)!);
    },
  },
  actions: {
    updateAnswer(cb: (answers: QuestionAnswer) => void) {
      cb(this.answers);
    },
    nextActiveQuestionKey(activeQuestionKey: HealthQuestionKey): QuestionKey {
      const activeQIndex = this.activeHealthQuestions.findIndex((question) => question.key === activeQuestionKey);
      return this.activeHealthQuestions[activeQIndex + 1]?.key;
    },
    concernIdToSubSectionKey(concernOptionId: number): SubSectionKey {
      const concernValue = HealthConcernsOptions.find((concern) => concern.id == concernOptionId)!.value;
      return concernToSubsectionKey[concernValue];
    },
    activateQuestions(selectedIds: number[]): void {
      const selectedSubsections = selectedIds.map((id) => this.concernIdToSubSectionKey(id));
      if (selectedSubsections.includes(SubSectionKey.HEALTHY_AGING)) {
        this.questBrainFog.subSection = SubSectionKey.HEALTHY_AGING;
      }
      this.questions.forEach((question) => {
        //LIBIDO_SIMPTOMS and ERECTILE_SIMPTOMS are optional questions, based on gender, even if there are in the LIBIDO Subsection
        if (
          [HealthQuestionKey.LIBIDO_SIMPTOMS, HealthQuestionKey.ERECTILE_SIMPTOMS].includes(
            question.key as HealthQuestionKey
          )
        )
          return;
        if (selectedSubsections.includes(question.subSection)) question.active = true;
      });
    },
    orderQuestionsByMainConcern(mainConcernId: number) {
      const mainSubSection = this.concernIdToSubSectionKey(mainConcernId);

      // the list will begin with the questions that have mainSubSection,
      // the rest of the question will be in the same order
      optionalSectionQuestions.sort((q1, q2) => {
        const isQ1Priority = q1.subSection === mainSubSection;
        const isQ2Priority = q2.subSection === mainSubSection;
        if ((isQ1Priority && isQ2Priority) || (!isQ1Priority && !isQ2Priority)) return 0;
        if (isQ1Priority) return -1;
        else return 1;
      });
      this.questions = [...introHealthQuestions, ...optionalSectionQuestions, ...lastHealthQuestions];
    },
    deactivateQuestions() {
      this.questBrainFog.subSection = SubSectionKey.MEMORY_FOCUS;
      this.questions.forEach((q) => {
        if ([SubSectionKey.HEALTH_CONCERNS, SubSectionKey.HEALTH_GOALS].includes(q.subSection)) return;
        q.active = false;
      });
    },
    goToNextStep() {
      const questStore = useQuestionsStore();
      let nextQuestionKey: QuestionKey = HealthQuestionKey.CONCERNS;

      switch (questStore.activeQuestionKey) {
        case HealthQuestionKey.CONCERNS: {
          const selectedIds = (this.answers[HealthQuestionKey.CONCERNS] as AnswerNumberList).value;
          this.activateQuestions(selectedIds);
          nextQuestionKey = this.nextActiveQuestionKey(HealthQuestionKey.CONCERNS);
          break;
        }
        case HealthQuestionKey.IMPROVE_SEX:
          switch (questStore.healthStore.gender) {
            case "female":
              nextQuestionKey = HealthQuestionKey.LIBIDO_SIMPTOMS;
              break;
            case "male":
              nextQuestionKey = HealthQuestionKey.ERECTILE_SIMPTOMS;
              break;
            default:
              nextQuestionKey = HealthQuestionKey.FERTILITY;
          }
          break;
        case HealthQuestionKey.ATHLETE:
          if (this.answers[HealthQuestionKey.ATHLETE].value === YesNoOptions[0].id) {
            nextQuestionKey = HealthQuestionKey.SWEAT;
            break;
          } else return createSectionRoute(SectionKey.BASICS);
        case HealthQuestionKey.INJURIES:
          return createSectionRoute(SectionKey.BASICS);
        default:
          nextQuestionKey = this.nextActiveQuestionKey(questStore.activeQuestionKey as HealthQuestionKey);
          break;
      }
      return createQuestionRoute(nextQuestionKey, SectionKey.HEALTH);
    },
    toggleActiveKey(cb: (questions: Question[]) => void) {
      cb(this.questions);
    },
    setHealthConcernsFromUrl(keys: (typeof HealthConcernsValues)[number][]) {
      const sanitizedKeys = keys.filter((key) => HealthConcernsValues.includes(key)).slice(0, 3);
      const ids = sanitizedKeys
        .map((key) => {
          return HealthConcernsOptions.find((concern) => concern.value === key)?.id;
        })
        .filter((id) => !!id);
      this.answers["h-concerns"].value = ids as number[];
    },
  },
});
